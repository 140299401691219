var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "py-3"
  }, [_c('h5', {
    staticClass: "main-text mb-3"
  }, [_vm._v("Write a comment")]), _c('ValidationObserver', {
    ref: "observer",
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var handleSubmit = _ref.handleSubmit;
        return [_c('form', {
          on: {
            "submit": function submit($event) {
              $event.preventDefault();
              return handleSubmit(_vm.submit);
            }
          }
        }, [_c('input-quill-editor', {
          attrs: {
            "vmodel": _vm.form.comment,
            "validation-label": "comment",
            "rules": {
              required: true
            },
            "placeholder": "Write a comment"
          },
          on: {
            "update:vmodel": function updateVmodel($event) {
              return _vm.$set(_vm.form, "comment", $event);
            }
          }
        }), _c('div', [_c('div', {
          staticClass: "attach-file d-flex bg-clr-lightgrey py-1"
        }, [_c('label', {
          staticClass: "btn btn-default"
        }, [_c('input', {
          attrs: {
            "type": "file",
            "hidden": "",
            "multiple": "",
            "id": "file_input"
          },
          on: {
            "change": function change($event) {
              return _vm.handleFileUpload($event);
            }
          }
        }), _c('span', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover",
            modifiers: {
              "hover": true
            }
          }],
          attrs: {
            "title": "Upload Files"
          }
        }, [_c('i', {
          staticClass: "fas fa-paperclip clr-bold-grey"
        })])]), _vm.form.comment_document.length ? _c('ul', {
          staticClass: "list-unstyled m-0 p-2 d-flex flex-wrap"
        }, _vm._l(_vm.form.comment_document, function (file, i) {
          return _c('li', {
            key: i
          }, [_c('span', {
            staticClass: "ticket-badge bg-white clr-primary font-14 mb-2 mx-2"
          }, [_vm._v(_vm._s(file.name)), _c('span', {
            directives: [{
              name: "b-tooltip",
              rawName: "v-b-tooltip.hover",
              modifiers: {
                "hover": true
              }
            }],
            staticClass: "close-file",
            attrs: {
              "title": "Remove File"
            },
            on: {
              "click": function click($event) {
                return _vm.removeFile(i);
              }
            }
          }, [_c('i', {
            staticClass: "fa fa-times"
          })])])]);
        }), 0) : _vm._e()]), _c('span', {
          staticClass: "d-inline-block font-14"
        }, [_vm._v("Allowed file types : jpeg,png,jpg,gif,svg,pdf,xls,xlsx,doc,docx,csv & txt upto 4Mb")])]), _c('button', {
          staticClass: "comment-submit-btn",
          class: {
            isDisabled: !_vm.row.status
          },
          attrs: {
            "type": "submit",
            "disabled": !_vm.row.status
          }
        }, [_vm._v(" Add Comment ")])], 1)];
      }
    }])
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }