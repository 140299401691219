<template>
  <div class="ticket-card">
    <slot name="ticket-card-header" />
    <div
      class="ticket-card-body"
      v-if="rows && rows.records && rows.records.length"
    >
      <div
        class="
          custom-border-bottom
          hover-bg-grey
          position-relative
          cursor-pointer
          ticket-component
        "
        v-for="(row, i) in rows.records"
        :key="i"
        :id="`ticket-${i}`"
        @click="handleIssueClick(row, `ticket-${i}`)"
      >
        <div class="single-ticket-wrap d-flex">
          <!-- <div class="user-img">
            <img
              src="@/assets/images/default-profile.jpg"
              alt=""
              class="img-fluid"
            />
          </div> -->
          <div class="ticket-details">
            <h3>{{ formattedTitle(row.title) }}</h3>
            <p class="ticket-summary mb-2">{{ strip(row.details) }}</p>
            <p class="ticket-date">
              <i class="far fa-calendar-alt" aria-hidden="true"></i>
              {{ row.created_at }}
            </p>
            <div class="ticket-tags">
              <span class="ticket-badge clr-white bg-clr-primary me-2">{{
                row.category.name
              }}</span>
              <span
                :class="[
                  'ticket-badge font-14 text-uppercase',
                  row.status ? 'is-opened' : 'is-closed',
                ]"
                ><i class="fa fa-circle me-1"></i
                >{{ row.status ? "Opened" : "Closed" }}</span
              >
            </div>
          </div>
          <span class="font-14 comment-icon">
            <img src="@/assets/images/comment.svg" alt="" />&nbsp;{{
              row.issue_comment_count
            }}
          </span>
        </div>
      </div>
      <slot name="pagination" />
    </div>
    <p class="no-data" v-else>No Records Found</p>
  </div>
</template>

<script>
export default {
  props: {
    rows: {
      required: true,
    },
    filters: {
      default: () => {},
    },
  },
  methods: {
    handleIssueClick(row, id) {
      this.$emit("issueClick", row);
      const element = document.getElementById(id);
      if (element) {
        element.classList.add("bg-clr-light");
      }
      this.resetBackgroundColor(element);
    },
    resetBackgroundColor(element = null) {
      let ticketComponents;
      ticketComponents = document.querySelectorAll(".ticket-component");
      ticketComponents.forEach((item) => {
        if (item != element) {
          item.classList.remove("bg-clr-light");
        }
      });
    },
    handleAddBtnClick() {
      let addBtn = document.getElementById("add-ticket-btn");
      if (!addBtn) {
        return;
      }
      addBtn.addEventListener("click", () => {
        this.resetBackgroundColor();
      });
    },
    strip(html) {
      let doc = new DOMParser().parseFromString(html, "text/html");
      return doc.body.textContent
        ? doc.body.textContent.length >= 130
          ? `${doc.body.textContent.substring(0, 130)} ...`
          : doc.body.textContent
        : "";
    },
    formattedTitle(str, len = 30) {
      if(str.length <= len){
        return str;
      }
      return str.substring(0, (str + ' ').lastIndexOf(' ', len)) + ' ...';
    },
  },
  mounted() {
    this.handleAddBtnClick();
  },
};
</script>
<style>
.comment-icon {
  position: absolute;
  right: 14px;
  bottom: 18px;
}
</style>
