<template>
  <div class="page-wrapper ticket-wrapper">
    <div class="container-fluid">
      <breadcrumb :items="breadcrumbItems" />
      <div class="page-title">
        <div class="split">
          <h1>Manage Tickets</h1>
        </div>
      </div>
      <div class="row">
        <div class="col-md-4">
          <ticket-list
            :rows.sync="rows"
            :filters="filters"
            @issueClick="getTicketDetail"
          >
            <template #ticket-card-header>
              <div class="split ticket-card-header custom-border-bottom">
                <h2 class="main-text">All Tickets</h2>
                <button
                  class="add-ticket-icon split"
                  v-b-tooltip.hover
                  title="Add Ticket"
                  id="add-ticket-btn"
                  @click="openForm"
                >
                  <i class="fa fa-plus"></i>
                </button>
              </div>
            </template>
            <template #pagination>
              <pagination
                :rows.sync="rows"
                :show-page-record="false"
                :filters="filters"
              />
            </template>
          </ticket-list>
        </div>
        <div class="col-md-8">
          <ticket-detail
            :row.sync="row"
            @onSuccess="handleSuccess"
            @handleTicketAction="handleTicketAction"
            v-if="!showForm"
          />
          <ticket-form v-if="showForm" @onSuccess="getTickets" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TicketList from "./partials/TicketList.vue";
import TicketDetail from "./partials/TicketDetail.vue";
import TicketForm from "./form/TicketForm.vue";
import axios from "axios";
import Helper from "@/resource/Helper";

export default {
  components: {
    TicketList,
    TicketDetail,
    TicketForm,
  },
  name: "ManageTicket",
  data: () => ({
    row: {},
    breadcrumbItems: [{ title: "Manage Tickets", active: true }],
    component: "",
    showForm: true,
    rows: [],
  }),
  computed: {
    filters() {
      const email = this.$store.getters.rep.email;
      return {
        email: email,
        user_type: "A",
      };
    },
  },
  methods: {
    getTicketDetail(row) {
      this.hideForm();
      this.row = row;
      let loader = this.$loading.show();
      let url = `${process.env.VUE_APP_TICKETING_URL_API}v1/issue/detail/${row.id}`;
      axios
        .get(url)
        .then((res) => {
          this.row = res.data.data;
        })
        .catch(() => {
          this.row = {};
        })
        .finally(() => {
          loader.hide();
        });
    },
    getTickets() {
      let loader = this.$loading.show();
      let url = `${process.env.VUE_APP_TICKETING_URL_API}v1/issue/list`;
      if (Object.keys(this.filters).length) {
        let filterUrl = Helper.objToUrlParams(this.filters);
        if (this.rows && Object.keys(this.rows).length) {
          const currentPage = this.rows.current_page;
          filterUrl = `${filterUrl}&page=${currentPage}`;
        }
        url = `${url}?${filterUrl}`;
      }
      axios
        .get(url)
        .then((res) => {
          this.rows = res.data;
        })
        .catch(() => {
          this.rows = null;
        })
        .finally(() => {
          loader.hide();
        });
    },
    handleTicketAction(row) {
      let action = {
        title: row.status ? "Close" : "Re-open",
        url: `${process.env.VUE_APP_TICKETING_URL_API}v1/issue/${
          row.status ? "close" : "reopen"
        }`,
      };
      this.$confirm({
        title: `${action.title} Ticket`,
        message: `Do you want to ${action.title.toLowerCase()} ticket ?`,
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: (confirm) => {
          if (confirm) {
            let loader = this.$loading.show();
            let url = `${action.url}/${row.id}`;
            axios
              .put(url)
              .then((res) => {
                this.$notify(res.data.message);
                this.getTickets();
                this.getTicketDetail(row);
              })
              .catch((err) => {
                this.$notify(err.response.data.message, "error");
              })
              .finally(() => {
                loader.hide();
              });
          }
        },
      });
    },
    openForm() {
      this.showForm = true;
    },
    hideForm() {
      this.showForm = false;
    },
    handleSuccess(row){
      this.getTicketDetail(row);
      // update comment count
      this.getTickets();
    }
  },
  created() {
    this.getTickets();
  },
};
</script>

<style src="@/assets/css/ticketing.css">
</style>

