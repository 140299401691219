<template>
  <div class="ticket-card">
    <div class="ticket-card-body" v-if="Object.keys(row).length">
      <div class="single-ticket hover-bg-none">
        <div class="pb-3 custom-border-bottom">
          <div class="split mb-3">
            <h2 class="main-text">Title: {{ row.title }}</h2>
            <div class="ticket-action">
              <div>
                <b-dropdown
                  size="sm"
                  dropright
                  id="ticket-dropdown"
                  toggle-class="text-decoration-none"
                  no-caret
                >
                  <template #button-content>
                    <span v-b-tooltip.hover title="Action"
                      ><i class="fa fa-ellipsis-v"></i
                    ></span>
                  </template>
                  <b-dropdown-item href="#" @click="handleTicketAction(row)"
                    >{{
                      row.status ? "Close" : "Re-open"
                    }}
                    Ticket</b-dropdown-item
                  >
                </b-dropdown>
              </div>
            </div>
          </div>
          <p class="ticket-date mb-2">
            <i class="far fa-calendar-alt" aria-hidden="true"></i>
            {{ row.created_at }}
          </p>
          <div class="ql-snow">
            <div class="ql-editor">
              <p v-html="row.details" />
            </div>
          </div>
        </div>
        <ticket-dcouments :row="row" />
        <ticket-comments :row="row" />
        <add-commnet :row="row" @onSuccess="onSuccess" />
      </div>
    </div>
    <p class="no-data" v-else>Please click on the issue to view details</p>
  </div>
</template>

<script>
import AddCommnet from "./AddComment.vue";
import TicketDcouments from "./TicketDocuments.vue";
import TicketComments from "./TicketComments.vue";
export default {
  components: {
    TicketDcouments,
    TicketComments,
    AddCommnet,
  },
  props: {
    row: {
      required: true,
      default: () => {},
    },
  },
  methods: {
    onSuccess(row) {
      this.$emit("onSuccess", row);
    },
    handleTicketAction(row){
      this.$emit('handleTicketAction',row);
    }
  },
};
</script>

<style>
#ticket-dropdown .dropdown-toggle {
  /**toggle dropdown button */
  background: none;
  border: none;
  color: #778699;
}

#ticket-dropdown ul.dropdown-menu {
  border: none;
  border-radius: 12px;
  box-shadow: 0px 0px 20px #523f6929;
}
.ticket-html-content img{
  max-width: 100%;
}
</style>
