<template>
  <div :class="formGroupClass">
    <validation-provider
      :name="validationLabel ? validationLabel : label.toLowerCase()"
      :rules="rules"
      v-slot="validationContext"
    >
      <label for="" v-if="label"
        >{{ label
        }}<span class="required-indc" v-if="rules && rules.required">
          *</span
        ></label
      >
      <quill-editor
        v-model="computedVmodel"
        :options="computedEditorOptions"
        :class="getInputClass(validationContext)"
      />
      <div class="error-text">
        <span>{{
          validationContext.errors[0]
            ? validationContext.errors[0]
            : apiErrors && Object.keys(apiErrors).length
            ? apiErrors[
                apiErrorKey ? apiErrorKey : label.toString().toLowerCase()
              ][0]
            : ""
        }}</span>
      </div>
    </validation-provider>
  </div>
</template>
<script>
export default {
  name: "InputQuillEditor",
  props: {
    apiErrors: {
      // Object with server side error flags
      required: false,
      default: () => {},
    },
    apiErrorKey: {
      // key pointing to particular entry in apiError Object
      required: false,
      default: "",
    },
    vmodel: {
      required: true,
    },
    label: {
      required: false,
      default: "",
    },
    placeholder: {
      required: false,
      default: "",
    },
    formGroupClass: {
      required: false,
      default: "col-md-12 mb-3",
    },
    rules: {
      type: Object,
      required: false,
      default: () => {},
    },
    validationLabel: {
      required: false,
      default: "",
    },
    errorKey: {
      // key name in apiError Object
      required: false,
    },
    editorOptions: {
      required: false,
      default: null,
    },
  },
  data: () => ({}),
  computed: {
    computedVmodel: {
      get: function () {
        return this.vmodel;
      },
      set: function (value) {
        this.$emit("update:vmodel", value ? value : "");
      },
    },
    computedEditorOptions() {
      if (this.editorOptions) {
        return this.editorOptions;
      }
      const placeholder = this.placeholder
        ? this.placeholder
        : "Enter Description ...";
      return {
        modules: {
          toolbar: [
            ["bold", "italic", "underline"],
            ["blockquote", "code-block"],
            [{ header: 1 }, { header: 2 }, { header: 3 }],
            [{ list: "ordered" }, { list: "bullet" }],
            [{ indent: "-1" }, { indent: "+1" }],
            [{ direction: "rtl" }],
            [{ size: ["small", false, "large", "huge"] }],
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            [{ color: [] }, { background: [] }],
            [{ align: [] }],
            ["clean"],
            ["link", "image"],
          ],
          imageResize: {
            //Add
            displayStyles: {
              //Add
              backgroundColor: "black",
              border: "none",
              color: "white",
            },
            modules: ["Resize", "DisplaySize", "Toolbar"],
          },
        },
        placeholder: placeholder,
      };
    },
  },
  methods: {
    getValidationState({ dirty, validated, valid = null }) {
      if (this.rules) {
        return dirty || validated ? valid : null;
      }
      return null;
    },
    getInputClass(validationContext) {
      let className = "";
      if (validationContext.errors[0]) {
        className = "is-invalid";
      } else if (validationContext.valid) {
        className = "is-valid";
      } else {
        className = "";
      }
      return `${className}`;
    },
  },
};
</script>

<style>
.ql-container {
  min-height: 220px;
}
.quill-editor.is-invalid .ql-container {
  border: 1px solid #dc3545 !important;
}
.quill-editor.is-invalid .ql-toolbar {
  border-bottom: 0;
}
.error-text {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #dc3545;
}
</style>
