var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.row && _vm.row.issue_comment && _vm.row.issue_comment.length ? _c('div', {
    staticClass: "custom-border-bottom py-3"
  }, [_c('h5', {
    staticClass: "main-text mb-3"
  }, [_vm._v("Comments")]), _c('div', {
    staticClass: "comment-container"
  }, [_c('vue-scroll', {
    attrs: {
      "ops": _vm.scrollOptions
    }
  }, [_c('div', {
    staticClass: "comment-wrap"
  }, _vm._l(_vm.row.issue_comment, function (comment, i) {
    return _c('div', {
      key: i
    }, [_c('div', {
      staticClass: "comment-box w-100",
      class: [comment.admin_reply ? 'bg-clr-lightgrey' : 'bg-clr-lightblue']
    }, [_c('span', {
      class: [comment.admin_reply ? 'arrow-left' : 'arrow-right']
    }), _c('div', {
      staticClass: "d-flex"
    }, [comment.admin_reply ? _c('div', {
      staticClass: "icon-image split justify-content-center"
    }, [_c('i', {
      staticClass: "fa fa-user text-white"
    })]) : _c('div', {
      staticClass: "user-image"
    }, [_c('img', {
      staticClass: "img-fluid",
      attrs: {
        "src": _vm.userImage,
        "alt": ""
      }
    })]), _c('div', {
      staticClass: "ms-3 flex-grow-1"
    }, [_c('div', {
      staticClass: "split"
    }, [_c('h6', {
      staticClass: "main-text mb-3"
    }, [_vm._v(" " + _vm._s(comment.admin_reply ? "Admin" : _vm.name) + " ")]), _c('span', {
      staticClass: "font-13 mb-2"
    }, [_vm._v(" " + _vm._s(comment.created_at) + " ")])]), _c('div', {
      staticClass: "ql-snow"
    }, [_c('div', {
      staticClass: "ql-editor"
    }, [_c('p', {
      domProps: {
        "innerHTML": _vm._s(comment.comment)
      }
    })])]), comment.document.length ? _c('file-list', {
      attrs: {
        "files": comment.document,
        "link-class": ['bg-white']
      }
    }) : _vm._e()], 1)])])]);
  }), 0)])], 1)]) : _vm._e()]);
}
var staticRenderFns = []

export { render, staticRenderFns }