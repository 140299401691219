var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    class: _vm.formGroupClass
  }, [_c('validation-provider', {
    attrs: {
      "name": _vm.validationLabel ? _vm.validationLabel : _vm.label.toLowerCase(),
      "rules": _vm.rules
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(validationContext) {
        return [_vm.label ? _c('label', {
          attrs: {
            "for": ""
          }
        }, [_vm._v(_vm._s(_vm.label)), _vm.rules && _vm.rules.required ? _c('span', {
          staticClass: "required-indc"
        }, [_vm._v(" *")]) : _vm._e()]) : _vm._e(), _c('quill-editor', {
          class: _vm.getInputClass(validationContext),
          attrs: {
            "options": _vm.computedEditorOptions
          },
          model: {
            value: _vm.computedVmodel,
            callback: function callback($$v) {
              _vm.computedVmodel = $$v;
            },
            expression: "computedVmodel"
          }
        }), _c('div', {
          staticClass: "error-text"
        }, [_c('span', [_vm._v(_vm._s(validationContext.errors[0] ? validationContext.errors[0] : _vm.apiErrors && Object.keys(_vm.apiErrors).length ? _vm.apiErrors[_vm.apiErrorKey ? _vm.apiErrorKey : _vm.label.toString().toLowerCase()][0] : ""))])])];
      }
    }])
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }