<template>
  <div class="py-3">
    <h5 class="main-text mb-3">Write a comment</h5>
    <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
      <form @submit.prevent="handleSubmit(submit)">
        <input-quill-editor
          :vmodel.sync="form.comment"
          validation-label="comment"
          :rules="{ required: true }"
          placeholder="Write a comment"
        />
        <div>
          <div class="attach-file d-flex bg-clr-lightgrey py-1">
            <label class="btn btn-default">
              <input
                type="file"
                hidden
                multiple
                id="file_input"
                @change="handleFileUpload($event)"
              />
              <span v-b-tooltip.hover title="Upload Files"
                ><i class="fas fa-paperclip clr-bold-grey"></i
              ></span>
            </label>
            <ul
              v-if="form.comment_document.length"
              class="list-unstyled m-0 p-2 d-flex flex-wrap"
            >
              <li v-for="(file, i) in form.comment_document" :key="i">
                <span
                  class="ticket-badge bg-white clr-primary font-14 mb-2 mx-2"
                  >{{ file.name
                  }}<span
                    class="close-file"
                    v-b-tooltip.hover
                    title="Remove File"
                    @click="removeFile(i)"
                  >
                    <i class="fa fa-times"></i> </span
                ></span>
              </li>
            </ul>
          </div>
          <span class="d-inline-block font-14"
            >Allowed file types : jpeg,png,jpg,gif,svg,pdf,xls,xlsx,doc,docx,csv
            & txt upto 4Mb</span
          >
        </div>
        <button
          type="submit"
          class="comment-submit-btn"
          :class="{ isDisabled: !row.status }"
          :disabled="!row.status"
        >
          Add Comment
        </button>
      </form>
    </ValidationObserver>
  </div>
</template>

<script>
import axios from "axios";
import InputQuillEditor from "@/components/form/InputQuillEditor.vue";
export default {
  components: {
    InputQuillEditor,
  },
  props: {
    row: {
      required: true,
    },
  },
  data: () => ({
    form: {
      comment: "",
      comment_document: [],
    },
  }),
  methods: {
    handleFileUpload(e) {
      // check for type error
      this.form.comment_document = [];
      let uploads;
      uploads = e.target.files;
      if (uploads && uploads.length) {
        for (let i = 0; i < uploads.length; i++) {
          this.form.comment_document.push(uploads[i]);
        }
      }
    },
    removeFile(i) {
      this.form.comment_document.splice(i, 1);
    },
    submit() {
      if (!this.form.comment && !this.form.comment_document.length) {
        this.$notify("Please add comment or upload file", "error");
        return false;
      }
      let loader = this.$loading.show();
      let url = `${process.env.VUE_APP_TICKETING_URL_API}v1/issue/comment`;
      let formData = new FormData();
      const form = { issue_id: this.row.id, ...this.form };
      for (const [key, value] of Object.entries(form)) {
        if (key == "comment_document") {
          for (let i = 0; i < form.comment_document.length; i++) {
            formData.append(`${key}[${i}]`, form.comment_document[i]);
          }
        } else {
          formData.append(key, value);
        }
      }
      axios
        .post(url, formData)
        .then((res) => {
          this.$notify(res.data.message);
          this.form = {
            comment: "",
            comment_document: [],
          };
          this.$emit("onSuccess", this.row);
        })
        .catch((err) => {
          this.$notify(err.response.data.message, "error");
        })
        .finally(() => {
          loader.hide();
        });
    },
  },
  watch: {
    row: {
      handler: function (row) {
        if (row) {
          this.$refs.observer.reset();
        }
      },
    },
  },
};
</script>

<style scoped>
.isDisabled {
  cursor: not-allowed;
}
</style>