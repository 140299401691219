<template>
  <div
    class="custom-border-bottom py-3"
    v-if="row && row.document && row.document.length"
  >
    <h5 class="main-text mb-3">Attachments</h5>
    <file-list :files="row.document" :show-file-size="true" />
  </div>
</template>

<script>
import FileList from "./FileList.vue";
export default {
  components: {
    FileList,
  },
  props: {
    row: {
      required: true,
      default: () => null,
    },
  },
};
</script>

