<template>
  <div class="ticket-card p-4">
    <div class="ticket-form">
      <div class="mb-3">
        <h2 class="main-text">New Ticket</h2>
        <span class="d-inline-bloc clr-bold-grey font-13"
          >Please fill the form below to submit your ticket.</span
        >
      </div>
      <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
        <form @submit.prevent="handleSubmit(submit)">
          <div class="form-group row">
            <div class="col-md-12 mb-3">
              <input-text
                :vmodel.sync="form.phone"
                label-for="phone"
                labelName="Phone"
                input-class="custom-input"
                :rules="{ required: true }"
              />
            </div>
          </div>
          <div class="form-group row">
            <div class="col-6 mb-3">
              <input-text
                :vmodel.sync="form.subject"
                label-for="subject"
                labelName="Subject"
                input-class="custom-input"
                :rules="{ required: true }"
              />
            </div>
            <div class="col-6 mb-3">
              <input-multi-select
                :vmodel.sync="form.category_id"
                label-for="category"
                labelName="Category"
                :options="
                  categories.length ? categories.map((item) => item.id) : []
                "
                :custom-label="
                  categories.length
                    ? (id) => categories.find((item) => item.id == id).name
                    : () => {}
                "
                :active-data="categories"
                :rules="{ required: true }"
              />
            </div>
          </div>
          <div class="form-group row">
            <div class="col-12 mb-3">
              <input-quill-editor
                :vmodel.sync="form.message"
                label="Message"
                :rules="{ required: true }"
                placeholder="Add your message here ..."
              />
            </div>
          </div>
          <div class="form-group row">
            <div class="col-12 mb-3">
              <input-file-multiple
                :vmodel.sync="form.issue_document"
                label-name="Upload Document"
                label-for="document"
                :rules="{ required: false }"
              >
                <template #uploadedFiles>
                  <span class="font-14 d-inline-block py-2 clr-lightgrey"
                    >Allowed file types :
                    jpeg,png,jpg,gif,svg,pdf,xls,xlsx,doc,docx,csv & txt</span
                  >
                </template>
              </input-file-multiple>
            </div>
          </div>
          <div class="form-group mb-3 pt-3 text-center">
            <button class="univ-btn">Add Ticket</button>
          </div>
        </form>
      </ValidationObserver>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import InputQuillEditor from "@/components/form/InputQuillEditor";

const FORM = {
  email: "",
  first_name: "",
  last_name: "",
  phone: "",
  subject: "",
  message: "",
  user_type: "A", //for rep dashboard
  category_id: "",
  issue_document: [],
};
export default {
  components: {
    InputQuillEditor,
  },
  data: () => ({
    form: {
      ...FORM,
    },
    categories: [],
  }),
  methods: {
    getCategories() {
      const url = `${process.env.VUE_APP_TICKETING_URL_API}v1/tickets/category`;
      axios
        .get(url)
        .then((res) => {
          this.categories = res.data.data;
        })
        .catch(() => {
          this.categories = [];
        });
    },
    submit() {
      let loader = this.$loading.show();
      const url = `${process.env.VUE_APP_TICKETING_URL_API}v1/tickets/send`;
      let formData = new FormData();
      for (const [key, value] of Object.entries(this.form)) {
        formData.append(key, value);
        if (
          key == "issue_document" &&
          this.form.issue_document &&
          this.form.issue_document.length
        ) {
          formData.delete("issue_document"); //reset
          for (let i = 0; i < this.form.issue_document.length; i++) {
            formData.append(`${key}[${i}]`, this.form.issue_document[i]);
          }
        }
      }
      axios
        .post(url, formData)
        .then((res) => {
          this.$notify(res.data.message);
          this.$emit("onSuccess");
          this.resetForm();
        })
        .catch((err) => {
          this.$notify(err.response.data.message, "error");
        })
        .finally(() => {
          loader.hide();
        });
    },
    resetForm() {
      const resetFields = {
        subject: "",
        message: "",
        category_id: "",
        issue_document: null,
      };
      Object.keys(resetFields).forEach((k) => {
        this.form[k] = resetFields[k];
      });
      this.$refs.observer.reset();
    },
    getUserDetails() {
      this.form.email = this.$store.getters.rep.email;
      this.form.first_name = this.$store.getters.rep.name;
      this.form.last_name = "";
      this.form.phone = this.validatePhone(this.$store.getters.rep.phone);
    },
    validatePhone(chars) {
      if(chars){
        return chars.replace(/\D/g, "");
      }
    },
  },
  mounted() {
    this.getCategories();
    this.getUserDetails();
  },
};
</script>
