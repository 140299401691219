var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "ticket-card p-4"
  }, [_c('div', {
    staticClass: "ticket-form"
  }, [_vm._m(0), _c('ValidationObserver', {
    ref: "observer",
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var handleSubmit = _ref.handleSubmit;
        return [_c('form', {
          on: {
            "submit": function submit($event) {
              $event.preventDefault();
              return handleSubmit(_vm.submit);
            }
          }
        }, [_c('div', {
          staticClass: "form-group row"
        }, [_c('div', {
          staticClass: "col-md-12 mb-3"
        }, [_c('input-text', {
          attrs: {
            "vmodel": _vm.form.phone,
            "label-for": "phone",
            "labelName": "Phone",
            "input-class": "custom-input",
            "rules": {
              required: true
            }
          },
          on: {
            "update:vmodel": function updateVmodel($event) {
              return _vm.$set(_vm.form, "phone", $event);
            }
          }
        })], 1)]), _c('div', {
          staticClass: "form-group row"
        }, [_c('div', {
          staticClass: "col-6 mb-3"
        }, [_c('input-text', {
          attrs: {
            "vmodel": _vm.form.subject,
            "label-for": "subject",
            "labelName": "Subject",
            "input-class": "custom-input",
            "rules": {
              required: true
            }
          },
          on: {
            "update:vmodel": function updateVmodel($event) {
              return _vm.$set(_vm.form, "subject", $event);
            }
          }
        })], 1), _c('div', {
          staticClass: "col-6 mb-3"
        }, [_c('input-multi-select', {
          attrs: {
            "vmodel": _vm.form.category_id,
            "label-for": "category",
            "labelName": "Category",
            "options": _vm.categories.length ? _vm.categories.map(function (item) {
              return item.id;
            }) : [],
            "custom-label": _vm.categories.length ? function (id) {
              return _vm.categories.find(function (item) {
                return item.id == id;
              }).name;
            } : function () {},
            "active-data": _vm.categories,
            "rules": {
              required: true
            }
          },
          on: {
            "update:vmodel": function updateVmodel($event) {
              return _vm.$set(_vm.form, "category_id", $event);
            }
          }
        })], 1)]), _c('div', {
          staticClass: "form-group row"
        }, [_c('div', {
          staticClass: "col-12 mb-3"
        }, [_c('input-quill-editor', {
          attrs: {
            "vmodel": _vm.form.message,
            "label": "Message",
            "rules": {
              required: true
            },
            "placeholder": "Add your message here ..."
          },
          on: {
            "update:vmodel": function updateVmodel($event) {
              return _vm.$set(_vm.form, "message", $event);
            }
          }
        })], 1)]), _c('div', {
          staticClass: "form-group row"
        }, [_c('div', {
          staticClass: "col-12 mb-3"
        }, [_c('input-file-multiple', {
          attrs: {
            "vmodel": _vm.form.issue_document,
            "label-name": "Upload Document",
            "label-for": "document",
            "rules": {
              required: false
            }
          },
          on: {
            "update:vmodel": function updateVmodel($event) {
              return _vm.$set(_vm.form, "issue_document", $event);
            }
          },
          scopedSlots: _vm._u([{
            key: "uploadedFiles",
            fn: function fn() {
              return [_c('span', {
                staticClass: "font-14 d-inline-block py-2 clr-lightgrey"
              }, [_vm._v("Allowed file types : jpeg,png,jpg,gif,svg,pdf,xls,xlsx,doc,docx,csv & txt")])];
            },
            proxy: true
          }], null, true)
        })], 1)]), _c('div', {
          staticClass: "form-group mb-3 pt-3 text-center"
        }, [_c('button', {
          staticClass: "univ-btn"
        }, [_vm._v("Add Ticket")])])])];
      }
    }])
  })], 1)]);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "mb-3"
  }, [_c('h2', {
    staticClass: "main-text"
  }, [_vm._v("New Ticket")]), _c('span', {
    staticClass: "d-inline-bloc clr-bold-grey font-13"
  }, [_vm._v("Please fill the form below to submit your ticket.")])]);
}]

export { render, staticRenderFns }